module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-546662-6","AW-1069872843"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gauges/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"4dd28c39613f5d2021000003"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"429241857500698"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
